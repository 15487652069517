var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Add New User ")]),_c('b-icon',{attrs:{"icon":"lock"}})],1),_c('div',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"horizontal":"","type":{'is-danger':errors[0], 'is-success':valid},"message":errors,"label":"Name"}},[_c('b-input',{key:"fullname",ref:"name",attrs:{"type":"text","name":"fullname","value":"fullname@domain.com","placeholder":"Chucky Armbruster"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"horizontal":"","type":{'is-danger':errors[0], 'is-success':valid},"message":errors,"label":"Email"}},[_c('b-input',{key:"email",attrs:{"type":"email","name":"email","value":"email@domain.com","placeholder":"newuser@aldorr.net"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"horizontal":"","type":{'is-danger':errors[0], 'is-success':valid},"message":errors,"label":"Password"}},[_c('b-input',{key:"password",attrs:{"type":"password","name":"password","placeholder":"something-secret-and-maybe-funny","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{staticStyle:{"margin-left":"auto"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"type":"is-success","icon-right":"lock"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Add")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }